<template>
  <div class="pa-5">

    <v-card>
      <se2a-data-list
        resource="qualification_plans"
        :actions="[]"
        :filters="[{name: 'Firstname', on: 'user.firstname'}, {name: 'Lastname', on: 'user.lastname'}, {name: 'Year', on: 'year'}, {name: 'Status', on: 'status'}]"
        :headers="headers"
      >
        <template v-slot:item.user="{ item }">
          {{ item.user.firstname }} {{ item.user.lastname }} ({{ item.user.email }})
        </template>

        <template v-slot:item.action="{ item }">
          <div class="actions">
            <v-btn :to="{name: 'admin-qualification-plan-detail', params: {qpId: item.id}}" icon>
              <v-icon>mdi-open-in-new</v-icon>
            </v-btn>
          </div>
        </template>
      </se2a-data-list>
    </v-card>
  </div>
</template>

<script>
import {defineComponent, onMounted, ref, watch} from "vue";
import {byApi} from "@/api/helpers";
import {CLIENT} from "@/api/client";
import Se2aDataList from "@/components/se2a-data-list.vue";

export default defineComponent({
  name: "se2a-qualification-plan-index",
  components: {Se2aDataList},
  setup() {
    const headers = [
      {text: 'User', value: 'user'},
      {text: 'Status', value: 'status'},
      {text: 'Year', value: 'year'},
      {text: 'Status', value: 'status'},
      {text: '', sortable: false, align: 'right', value: 'action'}
    ];

    const filters = [

    ]


    return {
      headers
    }
  }
});
</script>

<style scoped>

</style>
